const LogoSvg = ({width, height}) => {
  return (
    <svg
      width={width|| '250px'}
      height={height||"77px"}
      viewBox="30 17 508.000000 150.000000"
    >
      <g
      className="logo"
        transform="translate(0.000000,173.000000) scale(0.100000,-0.100000)"
        fill="black"
        stroke="none"
      >
        <path
          d="M916 1054 c-13 -13 -16 -42 -16 -162 0 -81 5 -154 10 -162 11 -18
170 -32 170 -15 0 16 229 240 237 232 4 -4 -45 -60 -107 -125 l-115 -117 115
113 c63 61 119 112 123 112 23 0 -5 -37 -102 -134 -89 -89 -115 -109 -140
-110 -36 -1 -43 -16 -7 -16 13 0 35 9 49 20 20 16 42 20 109 22 l83 2 -72 3
c-40 2 -73 7 -73 11 0 10 153 162 163 162 12 0 8 -130 -5 -153 -10 -20 -10
-21 5 -8 13 10 17 31 17 93 l0 80 45 51 45 52 -25 24 c-22 22 -27 23 -47 11
-13 -7 -88 -82 -168 -166 l-145 -152 -58 -1 c-98 -2 -97 -4 -97 167 0 102 4
152 12 160 16 16 380 17 411 0 20 -10 21 -10 8 5 -12 15 -39 17 -212 17 -166
0 -200 -2 -213 -16z m502 -26 c7 -7 12 -17 12 -22 0 -15 -80 -80 -87 -72 -9 9
28 46 45 46 12 0 12 3 2 15 -12 15 -7 45 8 45 4 0 13 -5 20 -12z m-48 -36 c0
-14 -39 -46 -47 -39 -3 4 2 16 12 27 19 21 35 26 35 12z"
        />
        <path
          d="M950 992 c0 -5 12 -14 27 -21 l28 -13 -28 -15 c-16 -8 -27 -17 -24
-19 6 -6 77 27 77 36 0 6 -64 40 -75 40 -3 0 -5 -4 -5 -8z"
        />
        <path
          d="M1670 870 c0 -109 2 -130 15 -130 12 0 15 17 16 93 l0 92 47 -92 c26
-51 53 -93 60 -93 7 0 33 42 57 93 l44 92 0 -92 c1 -76 4 -93 16 -93 13 0 15
21 15 130 0 79 -4 130 -10 130 -5 0 -35 -50 -66 -112 l-56 -112 -53 112 c-74
154 -85 152 -85 -18z"
        />
        <path
          d="M2087 983 c-2 -10 -28 -69 -55 -130 -34 -75 -47 -113 -38 -113 8 0
19 14 26 30 12 29 14 30 85 30 71 0 73 -1 85 -30 7 -16 19 -30 26 -30 8 0 14
2 14 5 0 2 -24 60 -54 127 -53 118 -78 149 -89 111z m48 -89 c14 -31 25 -60
25 -65 0 -5 -25 -9 -55 -9 -30 0 -55 4 -55 9 0 13 49 121 55 121 3 0 16 -25
30 -56z"
        />
        <path
          d="M2270 870 c0 -109 2 -130 15 -130 12 0 15 18 15 101 l0 101 78 -100
c42 -56 83 -101 90 -102 9 0 12 32 12 130 0 108 -3 130 -15 130 -11 0 -15 -20
-17 -101 l-3 -101 -78 101 c-42 55 -82 101 -87 101 -6 0 -10 -51 -10 -130z"
        />
        <path
          d="M2520 985 c0 -11 11 -15 40 -15 l40 0 0 -115 c0 -70 4 -115 10 -115
6 0 10 45 10 115 l0 115 40 0 c29 0 40 4 40 15 0 12 -17 15 -90 15 -73 0 -90
-3 -90 -15z"
        />
        <path
          d="M2790 883 c-67 -152 -65 -143 -47 -143 8 0 20 14 26 30 10 30 11 30
83 30 73 0 74 0 85 -30 6 -16 18 -30 27 -30 18 0 23 -13 -55 163 -23 53 -48
97 -54 97 -7 0 -36 -53 -65 -117z m94 6 c14 -33 26 -62 26 -65 0 -2 -25 -4
-55 -4 -37 0 -55 4 -55 13 0 17 44 117 51 117 4 0 18 -27 33 -61z"
        />
        <path
          d="M3042 984 c-22 -15 -29 -57 -14 -82 5 -7 33 -25 63 -40 56 -28 73
-58 49 -88 -19 -22 -77 -18 -92 6 -15 24 -38 27 -38 5 0 -20 58 -55 91 -55 15
0 38 10 53 21 22 17 27 28 24 58 -3 33 -8 38 -63 66 -49 24 -61 35 -63 57 -3
23 2 30 24 38 23 9 33 7 55 -10 33 -24 39 -25 39 -6 0 22 -40 46 -75 46 -17 0
-41 -7 -53 -16z"
        />
        <path
          d="M3340 870 c0 -156 18 -178 22 -27 l3 102 79 -102 c43 -57 85 -103 92
-103 11 0 14 28 14 130 0 108 -3 130 -15 130 -11 0 -15 -20 -17 -102 l-3 -103
-79 103 c-43 56 -83 102 -87 102 -5 0 -9 -58 -9 -130z"
        />
        <path
          d="M3686 968 c-7 -18 -32 -75 -55 -126 -45 -102 -45 -102 -31 -102 5 0
16 14 25 30 15 29 18 30 85 30 67 0 70 -1 85 -30 9 -16 22 -30 30 -30 10 0 0
32 -37 113 -27 61 -53 120 -55 130 -8 27 -32 20 -47 -15z m55 -75 c13 -32 26
-61 27 -65 4 -9 -102 -11 -110 -2 -7 6 42 124 52 124 3 0 17 -26 31 -57z"
        />
        <path
          d="M3861 992 c2 -5 28 -64 58 -131 37 -83 59 -121 69 -119 12 3 122 226
122 249 0 21 -22 6 -35 -23 -7 -18 -30 -69 -50 -113 l-36 -80 -48 110 c-32 72
-54 111 -66 113 -9 2 -16 0 -14 -6z"
        />
        <path
          d="M4150 870 c0 -109 2 -130 15 -130 13 0 15 21 15 130 0 109 -2 130
-15 130 -13 0 -15 -21 -15 -130z"
        />
        <path
          d="M4287 982 c-30 -19 -67 -85 -67 -121 0 -87 119 -154 199 -112 32 18
13 35 -23 21 -64 -24 -117 3 -138 71 -10 35 -9 45 6 74 27 50 97 71 147 45 15
-9 19 -7 19 5 0 35 -96 46 -143 17z"
        />
        <path
          d="M4480 870 c0 -109 2 -130 15 -130 11 0 15 11 15 38 1 22 9 47 20 60
l20 24 27 -33 c62 -74 80 -92 91 -88 7 2 -11 32 -44 72 l-56 68 53 56 c36 38
49 58 40 61 -8 3 -43 -26 -82 -69 l-68 -74 -1 73 c0 57 -3 72 -15 72 -13 0
-15 -21 -15 -130z"
        />
        <path
          d="M4720 870 c0 -109 2 -130 15 -130 13 0 15 21 15 130 0 109 -2 130
-15 130 -13 0 -15 -21 -15 -130z"
        />
        <path
          d="M4821 974 c-38 -49 -21 -78 68 -118 71 -33 64 -96 -11 -96 -15 0 -37
9 -48 20 -21 21 -40 26 -40 12 0 -19 49 -53 83 -59 31 -5 40 -1 67 25 16 17
30 39 30 49 0 25 -33 54 -88 78 -42 18 -65 56 -46 75 22 22 62 22 84 0 14 -14
25 -19 32 -12 15 15 -40 52 -78 52 -23 0 -38 -7 -53 -26z"
        />
      </g>
    </svg>
  )
}
export default LogoSvg
