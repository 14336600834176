const Sun = () => {
    return(

  <svg
  id="sun"
    xmlns="http://www.w3.org/2000/svg"
    width="30px"
    height="30px"
    viewBox="0 0 20 17"
  >
    <g >
      <circle
        cx="10"
        cy="9.96"
        r="4.1"
        fill="none"
        stroke="#14303f"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></circle>
      <line
        x1="10"
        y1="1.56"
        x2="10"
        y2="3.46"
        fill="none"
        stroke="#14303f"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></line>
      <line
        x1="10"
        y1="16.46"
        x2="10"
        y2="18.36"
        fill="none"
        stroke="#14303f"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></line>
      <line
        x1="18.4"
        y1="9.96"
        x2="16.5"
        y2="9.96"
        fill="none"
        stroke-width="2"
        stroke="#14303f"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></line>
      <line
        x1="3.5"
        y1="9.96"
        x2="1.6"
        y2="9.96"
        fill="none"
        stroke="#14303f"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></line>
      <line
        x1="15.9"
        y1="15.86"
        x2="14.6"
        y2="14.56"
        fill="none"
        stroke="#14303f"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></line>
      <line
        x1="5.4"
        y1="5.36"
        x2="4.1"
        y2="4.06"
        fill="none"
        stroke="#14303f"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></line>
      <line
        x1="15.9"
        y1="4.06"
        x2="14.6"
        y2="5.36"
        fill="none"
        stroke="#14303f"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></line>
      <line
        x1="5.4"
        y1="14.56"
        x2="4.1"
        y2="15.86"
        fill="none"
        stroke="#14303f"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></line>
    </g>
  </svg>
    )
}
export default Sun